
import Vue from 'vue';
import axios from '../../plugins/axios.js';
import { Provide, Component } from 'vue-property-decorator';
@Component

export default class CourseAuthoritySetting extends Vue {
    @Provide() private userCourse: { [index: string]: any } = {
        courseId: null,
        account: null,
        role: 'instructor',
    };
    @Provide() private isRequesting: boolean = false;

    private confirmAssignCourseToUser() {
        this.isRequesting = true;
        axios.post('/api/v1/course/authority', this.userCourse).then(() => {
            this.isRequesting = false;
            this.$message.success('更新成功');
        }).catch((error) => {
            this.isRequesting = false;
            this.$message.error(error);
        });
    }
}
