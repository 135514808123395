
import Vue from 'vue';
import { Provide, Component, Ref } from 'vue-property-decorator';
import axios from '../../plugins/axios.js';
import EditEmployeeModal from './modals/EditEmployeeModal.vue';

@Component({
    components: {
        EditEmployeeModal,
    },
})

export default class Employees extends Vue {
    @Provide() private employees: any[] = [];
    @Provide() private queriedCondition: { [index: string]: any } = {
        name: '',
        level: '',
        sort: { prop: 'id', order: 'ascending' },
        page: 1,
    };
    @Provide() private pageSize: number = 10;
    @Provide() private loading: boolean = true;
    @Provide() private total: number = 0;
    @Provide() private filterOptions: any = [
        { text: '高级', value: 3 },
        { text: '中级', value: 2 },
        { text: '初级', value: 1 },
        { text: '无', value: 0 },
    ];
    @Ref() private readonly editEmployeeModal;
    @Ref() private readonly employeesTable;

    private mounted() {
        this.getEmployees();
    }

    private refreshEmployees() {
        axios.post('/api/v1/crm/refresh-employees').then(() => {
            this.getEmployees();
        }).catch((error) => {
            this.$message.error(error);
        });
    }

    private changeFilter(filter: any) {
        if (filter.hasOwnProperty('level')) {
            this.queriedCondition.level = filter.level;
        }
        this.queriedCondition.page = 1;
        this.getEmployees();
    }

    private changePage(page: number) {
        this.queriedCondition.page = page;
        this.getEmployees();
    }

    private queryEmployees() {
        this.employeesTable.clearFilter();
        this.queriedCondition.level = [];
        this.queriedCondition.page = 1;
        this.getEmployees();
    }

    private getEmployees() {
        this.loading = true;
        axios.get('/api/v1/crm/employees', this.queriedCondition).then(({ total, employees }) => {
            this.employees = employees;
            this.total = total;
        }).catch((error) => {
            this.$message.error(error);
        }).then(() => {
            this.loading = false;
        });
    }

    private formattedLevelType(level: number) {
        const levelTagTypes = ['info', 'success', 'warning', 'danger'];
        return levelTagTypes[level] || '';
    }

    private formattedLevelText(level: number) {
        const levelTagTexts = ['无', '初级', '中级', '高级'];
        return levelTagTexts[level] || '';
    }

    private changeSchedulingInStatus(employee: any) {
        axios.post('/api/v1/crm/change-employee-in-scheduling', {
            employee_id: employee.id,
            in_scheduling_status: employee.isInScheduling ? 1 : 0,
        }).catch((error) => {
            employee.isInScheduling = !employee.isInScheduling;
            this.$message.error(error);
        });
    }

    private changeSchedulingNotInStatus(employee: any) {
        axios.post('/api/v1/crm/change-employee-notin-scheduling', {
            employee_id: employee.id,
            notin_scheduling_status: employee.isNotInScheduling ? 1 : 0,
        }).catch((error) => {
            employee.isNotInScheduling = !employee.isNotInScheduling;
            this.$message.error(error);
        });
    }

    private openEditEmployeeModal(employee: any) {
        this.editEmployeeModal.$emit('open', employee);
    }
}
