import Vue from 'vue';
import Router from 'vue-router';
import Home from '../views/Home.vue';
import EducationSystem from '../views/educationSystem/EducationSystem.vue';
import CoursesManagement from '../views/educationSystem/CoursesManagement.vue';
import CourseLecturesManagement from '../views/educationSystem/widgets/LiveCourseLectures.vue';
import Instructors from '../views/educationSystem/Instructors.vue';
import TutoringManage from '../views/educationSystem/TutoringManage.vue';
import CoursesLectures from '../views/educationSystem/CoursesLectures.vue';
import ProblemSetEvaluationManage from '../views/educationSystem/ProblemSetEvaluationManage.vue';
import LecturePreparationManage from '../views/educationSystem/LecturePreparationManage.vue';

import Crm from '../views/crm/Crm.vue';
import CustomerManagement from '../views/crm/CustomerManagement.vue';
import Employees from '../views/crm/Employees.vue';
import EmployeesStatistics from '../views/crm/EmployeesStatistics.vue';
import CustomerInputStatistics from '../views/crm/CustomerInputStatistics.vue';
import PayInvitation from '../views/crm/PayInvitation.vue';
import CouponManagement from '../views/crm/CouponManagement.vue';
import CouponTemplateManagement from '../views/crm/CouponTemplateManagement.vue';
import TransactionManagement from '../views/crm/TransactionManagement.vue';
import VoucherManagement from '../views/crm/VoucherManagement.vue';
import TransactionRefundManagement from '../views/crm/TransactionRefundManagement.vue';
import PotentialCustomer from '../views/crm/PotentialCustomer.vue';

import Sem from '../views/sem/Sem.vue';
import LaunchCluesStatistic from '../views/sem/LaunchCluesStatistic.vue';

import FinancialManage from '../views/financialManage/FinancialManage.vue';
import InvoiceManage from '../views/financialManage/InvoiceManage.vue';
import CreateInvoice from '../views/financialManage/CreateInvoice.vue';

import ExpressManage from '../views/expressManage/ExpressManage.vue';
import ExpressInput from '../views/expressManage/ExpressInput.vue';

import AdvisorSystem from '../views/advisorSystem/AdvisorSystem.vue';
import ParentNotification from '../views/advisorSystem/ParentNotification.vue';
import SwitchCourseRecord from '../views/advisorSystem/SwitchCourseRecord.vue';
import DropCourseRecord from '../views/advisorSystem/DropCourseRecord.vue';
import CoursesManage from '../views/advisorSystem/CoursesManage.vue';
import StudentsManagement from '../views/advisorSystem/StudentsManagement.vue';
import CourseStudentsManagement from '../views/advisorSystem/CourseStudentsManagement.vue';

import Other from '../views/other/Other.vue';
import CrossdomainAllowlistManagement from '../views/other/CrossdomainAllowlistManagement.vue';

import Administration from '../views/administration/Administration.vue';
import AuthorityManage from '../views/administration/AuthorityManage.vue';
import CourseAuthoritySetting from '../views/administration/CourseAuthoritySetting.vue';

import MarketingManage from '../views/marketingManage/MarketingManage.vue';
import AdSetting from '../views/marketingManage/AdSetting.vue';
import MultimediaUpload from '../views/marketingManage/MultimediaUpload.vue';
import ReferralManagement from '../views/marketingManage/ReferralManagement.vue';
import MarketingCampaignManagement from '../views/marketingManage/MarketingCampaignManagement.vue';

import Partner from '../views/partnerSystem/Partner.vue';
import PartnerManagement from '../views/partnerSystem/PartnerManagement.vue';
import PartnerBilling from '../views/partnerSystem/PartnerBilling.vue';
import PartnerTransaction from '../views/partnerSystem/PartnerTransaction.vue';
import PartnerCourses from '../views/partnerSystem/PartnerCourses.vue';
import PartnerOperationApproval from '../views/partnerSystem/PartnerOperationApproval.vue';
import PartnerDataSummary from '../views/partnerSystem/PartnerDataSummary.vue';

import RouterBeforeEnter from '../plugins/before-enter.js';
import PageMissing from '../views/PageMissing.vue';

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return (originalPush.call(this, location) as any).catch((err: any) => err);
};

Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home,
            meta: { title: '首页 - 蒜台' },
            beforeEnter: RouterBeforeEnter.assignTitle,
        },
        {
            name: 'education-system',
            path: ':id',
            component: EducationSystem,
            children: [
                {
                    path: '/instructors',
                    name: 'instructors',
                    meta: { title: '教师团队 - 教务系统 - 蒜台' },
                    component: Instructors,
                    beforeEnter: RouterBeforeEnter.assignTitle,
                },
                {
                    path: '/courses-management',
                    name: 'courses-management',
                    meta: { title: '开班管理 - 教务系统 - 蒜台' },
                    component: CoursesManagement,
                    beforeEnter: RouterBeforeEnter.assignTitle,
                },
                {
                    path: '/course-lectures-management',
                    name: 'course-lectures-management',
                    meta: { title: '排班 - 教务系统 - 蒜台' },
                    component: CourseLecturesManagement,
                    props: (route) => ({ courseId: route.query.courseId }),
                    beforeEnter: RouterBeforeEnter.assignTitle,
                },
                {
                    path: '/courses-lectures-management',
                    name: 'courses-lectures-management',
                    meta: { title: '课表管理 - 教务系统 - 蒜台' },
                    component: CoursesLectures,
                    beforeEnter: RouterBeforeEnter.assignTitle,
                },
                {
                    path: '/tutoring-manage',
                    name: 'tutoring-manage',
                    meta: { title: '答疑管理 - 教务系统 - 蒜台' },
                    component: TutoringManage,
                    beforeEnter: RouterBeforeEnter.assignTitle,
                },
                {
                    path: '/problem-set-evaluation-manage',
                    name: 'problem-set-evaluation-manage',
                    meta: { title: '真题测评管理 - 教务系统 - 蒜台' },
                    component: ProblemSetEvaluationManage,
                    beforeEnter: RouterBeforeEnter.assignTitle,
                },
                {
                    path: '/lecture-preparation-manage',
                    name: 'lecture-preparation-manage',
                    meta: { title: '备课管理 - 教务系统 - 蒜台' },
                    component: LecturePreparationManage,
                    beforeEnter: RouterBeforeEnter.assignTitle,
                },
            ],
        },
        {
            name: 'crm',
            path: ':id',
            component: Crm,
            children: [
                {
                    path: '/sales-scheduling',
                    name: 'sales-scheduling',
                    meta: { title: '销售/学管管理 - CRM 系统 - 蒜台' },
                    component: Employees,
                    beforeEnter: RouterBeforeEnter.assignTitle,
                },
                {
                    path: '/sales-scheduling-statistics',
                    name: 'sales-scheduling-statistics',
                    meta: { title: '销售线索分配统计 - CRM 系统 - 蒜台' },
                    component: EmployeesStatistics,
                    beforeEnter: RouterBeforeEnter.assignTitle,
                },
                {
                    path: '/customer-management',
                    name: 'customer-management',
                    meta: { title: '客户信息录入 - CRM 系统 - 蒜台' },
                    component: CustomerManagement,
                    beforeEnter: RouterBeforeEnter.assignTitle,
                },
                {
                    path: '/customer-input-statistics',
                    name: 'customer-input-statistics',
                    meta: { title: '客户信息统计 - CRM 系统 - 蒜台' },
                    component: CustomerInputStatistics,
                    beforeEnter: RouterBeforeEnter.assignTitle,
                },
                {
                    path: '/pay-invitation',
                    name: 'pay-invitation',
                    meta: { title: '支付邀请 - CRM 系统 - 蒜台' },
                    component: PayInvitation,
                    beforeEnter: RouterBeforeEnter.assignTitle,
                },
                {
                    path: '/coupon-management',
                    name: 'coupon-management',
                    meta: { title: '优惠券管理 - CRM 系统 - 蒜台' },
                    component: CouponManagement,
                    beforeEnter: RouterBeforeEnter.assignTitle,
                },
                {
                    path: '/coupon-template-management',
                    name: 'coupon-template-management',
                    meta: { title: '优惠券模版管理 - CRM 系统 - 蒜台' },
                    component: CouponTemplateManagement,
                    beforeEnter: RouterBeforeEnter.assignTitle,
                },
                {
                    path: '/transaction-manage',
                    name: 'transaction-manage',
                    meta: { title: '订单管理 - CRM 系统 - 蒜台' },
                    component: TransactionManagement,
                    beforeEnter: RouterBeforeEnter.assignTitle,
                },
                {
                    path: '/voucher-management',
                    name: 'voucher-management',
                    meta: { title: '权益管理 - CRM 系统 - 蒜台' },
                    component: VoucherManagement,
                    beforeEnter: RouterBeforeEnter.assignTitle,
                },
                {
                    path: '/transaction-refund-management',
                    name: 'transaction-refund-management',
                    meta: { title: '退款管理 - CRM 系统 - 蒜台' },
                    component: TransactionRefundManagement,
                    beforeEnter: RouterBeforeEnter.assignTitle,
                },
                {
                    path: '/potential-customer',
                    name: 'potential-customer',
                    meta: { title: '潜在客户 - CRM 系统 - 蒜台' },
                    component: PotentialCustomer,
                    beforeEnter: RouterBeforeEnter.assignTitle,
                },
            ],
        },
        {
            name: 'sem',
            path: ':id',
            component: Sem,
            children: [
                {
                    path: '/launch-clues-statistic',
                    name: 'launch-clues-statistic',
                    meta: { title: '投放成单统计 - SEM 系统 - 蒜台' },
                    component: LaunchCluesStatistic,
                    beforeEnter: RouterBeforeEnter.assignTitle,
                },
            ],
        },
        {
            name: 'financial-manage',
            path: ':id',
            component: FinancialManage,
            children: [
                {
                    path: '/invoice-manage',
                    name: 'invoice-manage',
                    meta: { title: '发票管理 - 财务管理 - 蒜台' },
                    component: InvoiceManage,
                    beforeEnter: RouterBeforeEnter.checkInvoicePermission,
                },
                {
                    path: '/create-invoice',
                    name: 'create-invoice',
                    meta: { title: '发票管理 - 财务管理 - 蒜台' },
                    component: CreateInvoice,
                    beforeEnter: RouterBeforeEnter.assignTitle,
                    props: (route) => ({ swiftId: route.query.swiftid }),
                },
            ],
        },
        {
            name: 'express-manage',
            path: ':id',
            component: ExpressManage,
            children: [
                {
                    path: '/express-input',
                    name: 'express-input',
                    meta: { title: '快递信息录入 - 快递管理 - 蒜台' },
                    component: ExpressInput,
                    beforeEnter: RouterBeforeEnter.assignTitle,
                },
            ],
        },
        {
            name: 'advisor-system',
            path: ':id',
            component: AdvisorSystem,
            children: [
                {
                    path: '/class-manage',
                    name: 'class-manage',
                    meta: { title: '班级管理 - 班主任系统 - 蒜台' },
                    component: CoursesManage,
                    beforeEnter: RouterBeforeEnter.assignTitle,
                },
                {
                    path: '/class-student-manage',
                    name: 'class-student-manage',
                    meta: { title: '班级学生管理 - 班主任系统 - 蒜台' },
                    component: CourseStudentsManagement,
                    props: (route) => ({ courseId: route.query.courseId }),
                    beforeEnter: RouterBeforeEnter.assignTitle,
                },
                {
                    path: '/advisor-students-management',
                    name: 'advisor-students-management',
                    meta: { title: '学生管理 - 班主任系统 - 蒜台' },
                    component: StudentsManagement,
                    beforeEnter: RouterBeforeEnter.assignTitle,
                },
                {
                    path: '/parent-notification',
                    name: 'parent-notification',
                    meta: { title: '家长消息管理 - 班主任系统 - 蒜台' },
                    component: ParentNotification,
                    beforeEnter: RouterBeforeEnter.assignTitle,
                },
                {
                    path: '/switch-course-record',
                    name: 'switch-course-record',
                    meta: { title: '转班记录 - 班主任系统 - 蒜台' },
                    component: SwitchCourseRecord,
                    beforeEnter: RouterBeforeEnter.assignTitle,
                },
                {
                    path: '/drop-course-record',
                    name: 'drop-course-record',
                    meta: { title: '退班记录 - 班主任系统 - 蒜台' },
                    component: DropCourseRecord,
                    beforeEnter: RouterBeforeEnter.assignTitle,
                },
            ],
        },
        {
            name: 'other',
            path: ':id',
            component: Other,
            children: [
                {
                    path: '/crossdomain-allowlist-management',
                    name: 'crossdomain-allowlist-management',
                    meta: { title: '其他功能 - 跨域白名单管理  - 蒜台' },
                    component: CrossdomainAllowlistManagement,
                    beforeEnter: RouterBeforeEnter.assignTitle,
                },
            ],
        },
        {
            name: 'marketing-manage',
            path: ':id',
            component: MarketingManage,
            children: [
                {
                    path: '/ad-setting',
                    name: 'ad-setting',
                    meta: { title: '广告管理 - 营销管理 - 蒜台' },
                    component: AdSetting,
                    beforeEnter: RouterBeforeEnter.assignTitle,
                },
                {
                    path: '/upload-multimedia',
                    name: 'upload-multimedia',
                    meta: { title: '资料上传 - 营销管理 - 蒜台' },
                    component: MultimediaUpload,
                    beforeEnter: RouterBeforeEnter.assignTitle,
                },
                {
                    path: '/referral-management',
                    name: 'referral-management',
                    meta: { title: '转介绍管理 - 营销管理 - 蒜台' },
                    component: ReferralManagement,
                    beforeEnter: RouterBeforeEnter.assignTitle,
                },
                {
                    path: '/marketing-campaign-management',
                    name: 'marketing-campaign-management',
                    meta: { title: '营销活动管理 - 营销管理 - 蒜台' },
                    component: MarketingCampaignManagement,
                    beforeEnter: RouterBeforeEnter.assignTitle,
                },
            ],
        },
        {
            name: 'business',
            path: ':id',
            component: Partner,
            children: [
                {
                    path: '/partner-manage',
                    name: 'partner-manage',
                    meta: { title: '机构管理 - 对公业务系统 - 蒜台' },
                    component: PartnerManagement,
                    beforeEnter: RouterBeforeEnter.assignTitle,
                },
                {
                    path: '/partner-billing',
                    name: 'partner-billing',
                    meta: { title: '机构计费 - 对公业务系统 - 蒜台' },
                    component: PartnerBilling,
                    beforeEnter: RouterBeforeEnter.assignTitle,
                },
                {
                    path: '/partner-transaction',
                    name: 'partner-transaction',
                    meta: { title: '机构账单 - 对公业务系统 - 蒜台' },
                    component: PartnerTransaction,
                    props: (route) => ({ partnerCode: route.query.partnerCode }),
                    beforeEnter: RouterBeforeEnter.assignTitle,
                },
                {
                    path: '/partner-course-management',
                    name: 'partner-course-management',
                    meta: { title: '机构管理 - 对公业务系统 - 蒜台' },
                    component: PartnerCourses,
                    props: (route) => ({ partnerCode: route.query.partnerCode }),
                    beforeEnter: RouterBeforeEnter.assignTitle,
                },
                {
                    path: '/partner-operation-approval',
                    name: 'partner-operation-approval',
                    meta: { title: '机构操作审批 - 对公业务系统 - 蒜台' },
                    component: PartnerOperationApproval,
                    beforeEnter: RouterBeforeEnter.assignTitle,
                },
                {
                    path: '/partner-data-summary',
                    name: 'partner-data-summary',
                    meta: { title: '机构数据汇总 - 对公业务系统 - 蒜台' },
                    component: PartnerDataSummary,
                    beforeEnter: RouterBeforeEnter.assignTitle,
                },
            ],
        },
        {
            name: 'administration',
            path: ':id',
            component: Administration,
            children: [
                {
                    path: '/authority-manage',
                    name: 'authority-manage',
                    meta: { title: '权限管理 - 管理功能 - 蒜台' },
                    component: AuthorityManage,
                    beforeEnter: RouterBeforeEnter.assignTitle,
                },
                {
                    path: '/course-authority-setting',
                    name: 'course-authority-setting',
                    meta: { title: '课程权限 - 管理功能 - 蒜台' },
                    component: CourseAuthoritySetting,
                    beforeEnter: RouterBeforeEnter.assignTitle,
                },
            ],
        },
        {
            path: '*',
            name: '404',
            meta: { title: '页面不存在 - 蒜台' },
            component: PageMissing,
            beforeEnter: RouterBeforeEnter.assignTitle,
        },
    ],
});
