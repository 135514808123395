
import Vue from 'vue';
import axios from '@/plugins/axios.js';
import UploadSingleImage from '../widgets/UploadSingleImage.vue';
import { Provide, Component, Prop, Ref } from 'vue-property-decorator';
import _ from 'lodash';

@Component({
    components: {
        UploadSingleImage,
    },
})
export default class UpdateCampaignModal extends Vue {
    @Prop() private vouchers: any;

    @Provide() private campaign: any = {};
    @Provide() private showDialog: boolean = false;
    @Provide() private submitting: boolean = false;
    @Provide() private rules: { [index: string]: object[] } = {
        title: [
            { required: true, message: '请填写活动标题', trigger: 'blur' },
        ],
        urlOfPoster: [
            { required: true, message: '请上传活动海报图片', trigger: 'blur' },
        ],
        urlOfSmallThumbnail: [
            { required: true, message: '请上传缩略图(小)', trigger: 'blur' },
        ],
        urlOfBigThumbnail: [
            { required: true, message: '请上传缩略图(大)', trigger: 'blur' },
        ],
        voucherId: [
            { required: true, message: '请选择绑定权益', trigger: 'blur' },
        ],
        isImmediate: [
            { required: true, message: '请选择是否立即自动兑换', trigger: 'blur' },
        ],
        instruction: [
            { required: true, message: '请填写兑换后文字说明', trigger: 'blur' },
        ],
        buttonColor: [
            { required: true, message: '请选择按钮颜色', trigger: 'blur' },
        ],
        supportGroupBuying: [
            { required: true, message: '请选择是否支持拼团', trigger: 'blur' },
        ],
        groupBuyingType: [
            { required: true, message: '请选择拼团类型', trigger: 'blur' },
        ],
        groupBuyingAmount: [
            { required: true, message: '请填写团购金额', trigger: 'blur' },
        ],
        groupBuyingDuration: [
            { required: true, message: '请填写拼团时长', trigger: 'blur' },
        ],
    };
    @Ref() private readonly campaignForm;

    private created() {
        this.$on('open', (campaign) => {
            this.showDialog = true;
            this.campaign = _.cloneDeep(campaign);
        });
    }

    private get isAddingCampaign() {
        return !this.campaign.id;
    }

    private get title() {
        return this.isAddingCampaign ? '添加营销活动' : '编辑营销活动';
    }

    private clearValidate() {
        this.campaignForm.clearValidate();
    }

    private confirm() {
        this.campaignForm.validate((valid) => {
            if (!valid) {
                return false;
            }
            this.submitting = true;
            this.updateGroupBuyingParams();
            this.updateCampaign().then(() => {
                this.$message({
                    type: 'success',
                    message: '更新活动成功',
                });
                this.submitting = false;
                this.showDialog = false;
                this.$emit('refresh');
            }).catch((error) => {
                this.submitting = false;
                this.$message.error(error);
            });
        });
    }

    private updateGroupBuyingParams() {
        if (!this.campaign.supportGroupBuying) {
            this.campaign.groupBuyingType = 0;
            this.campaign.groupBuyingAmount = null;
            this.campaign.groupBuyingDuration = null;
        }
    }

    private updateCampaign() {
        if (this.isAddingCampaign) {
            return axios.post('/api/v1/marketing-campaign-management/campaign', this.campaign);
        }
        return axios.put('/api/v1/marketing-campaign-management/campaign', this.campaign);
    }
}
