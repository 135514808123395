
import Vue from 'vue';
import { Provide, Component } from 'vue-property-decorator';
import axios from '../../plugins/axios.js';

@Component
export default class EmployeesStatistics extends Vue {
    @Provide() private employeesStatistics: any[] = [];
    @Provide() private loading: boolean = true;

    private mounted() {
        this.getEmployeesStatistics();
    }

    private getEmployeesStatistics() {
        this.loading = true;
        axios.get('/api/v1/crm/employee-statistics').then(({ crm_employees }) => {
            this.formatEmployeesStatistics(crm_employees);
        }).catch((error) => {
            this.$message.error(error);
        }).then(() => {
            this.loading = false;
        });
    }

    private formattedLevelType(level: number) {
        const levelTagTypes = ['info', 'success', 'warning', 'danger'];
        return levelTagTypes[level] || '';
    }

    private formattedLevelText(level: number) {
        const levelTagTexts = ['无', '初级', '中级', '高级'];
        return levelTagTexts[level] || '';
    }

    private formatEmployeesStatistics(employees: any) {
        this.employeesStatistics = [];
        for (const employee of employees) {
            this.employeesStatistics.push({
                id: parseInt(employee.employee_id, 0),
                name: employee.name,
                account: employee.account,
                level: employee.reception_level,
                inSchedulingWorkingDuration: employee.in_scheduling_working_duration,
                notinSchedulingWorkingDuration: employee.notin_scheduling_working_duration,
                monthReferralCount: employee.month_referral_count,
                monthInSchedulingRecordCount: employee.month_in_scheduling_record_count,
                todayInSchedulingRecordCount: employee.today_in_scheduling_record_count,
                yesterdayInSchedulingRecordCount: employee.yesterday_in_scheduling_record_count,
                monthNotinSchedulingRecordCount: employee.month_notin_scheduling_record_count,
                todayNotinSchedulingRecordCount: employee.today_notin_scheduling_record_count,
                yesterdayNotinSchedulingRecordCount: employee.yesterday_notin_scheduling_record_count,
            });
        }
    }
}
