
import Vue from 'vue';
import axios from '../../plugins/axios.js';
import { Provide, Component, Ref } from 'vue-property-decorator';
import ConfirmRefundModal from './modals/ConfirmRefundModal.vue';

@Component({
    components: {
        ConfirmRefundModal,
    },
})
export default class TransactionRefundManagement extends Vue {
    @Provide() private refundRecords: any[] = [];
    @Provide() private totalRecords: number = 0;
    @Provide() private pageSize: number = 10;
    @Provide() private queriedCondition: { [index: string]: any } = {
        page: 1,
    };
    @Provide() private loading: boolean = false;
    @Ref() private readonly confirmRefundModal;

    private mounted() {
        this.requestRefundRecords();
    }

    private changePage(page: number) {
        this.queriedCondition.page = page;
        this.requestRefundRecords();
    }

    private requestRefundRecords() {
        this.loading = true;
        axios.get('/api/v1/transaction/refund-records', this.queriedCondition).then(({ records, total }) => {
            this.loading = false;
            this.refundRecords = records;
            this.totalRecords = total;
        }).catch((error) => {
            this.$message.error(error);
        });
    }

    private refundStatusText(status: number) {
        return status === 0 ? '退款中' : '已退款';
    }

    private refundChannelText(channel: number | null) {
        switch (channel) {
            case 0: return '原路返回';
            case 1: return '微信转款';
            case 2: return '支付宝转款';
            case 3: return '银行转款';
            default: return '';
        }
    }

    private openConfirmRefundModal(record: any) {
        this.confirmRefundModal.$emit('open', record);
    }
}
