
import Vue from 'vue';
import axios from '../../../plugins/axios.js';
import { Provide, Component, Prop, Watch, Ref } from 'vue-property-decorator';
import AddTemplateCoursesModal from './AddTemplateCourses.vue';
import _ from 'lodash';

@Component({
    components: {
        AddTemplateCoursesModal,
    },
})
export default class EditPartner extends Vue {
    @Prop() private templateCourses!: any;
    @Prop() private selectedPartner;
    @Prop() private addressesOptions;
    @Prop() private problemAccessQuotaUnitPrice;
    @Prop() private problemSetAccessQuotaUnitPrice;
    @Provide() private originalProblemAccessQuota: any = 0;
    @Provide() private originalProblemSetAccessQuota: any = 0;
    @Provide() private isRequesting: boolean = false;
    @Provide() private filteredTemplateCourses: any = [];
    @Provide() private showDialog: boolean = false;
    @Provide() private partner: { [index: string]: any } = {
        partnerName: '',
        partnerCode: '',
        instructorQuota: 0,
        problemAccessQuota: 0,
        problemSetAccessQuota: 0,
        description: '',
        contractTime: [],
        address: [],
    };
    @Provide() private rules: { [index: string]: object[] } = {
        partnerName: [
            { required: true, message: '请填写机构名', trigger: 'blur' },
        ],
        instructorQuota: [
            { required: true, message: '请填写教师数量上限', trigger: 'blur' },
            { validator: this.validateNumber, trigger: 'blur' },
        ],
        problemAccessQuota: [
            { validator: this.validateNumber, trigger: 'blur' },
        ],
        problemSetAccessQuota: [
            { validator: this.validateNumber, trigger: 'blur' },
        ],
    };
    @Ref() private readonly partnerForm;
    @Ref() private readonly addTemplateCoursesModal;
    private created() {
        this.$on('open', () => {
            this.showDialog = true;
        });
    }
    private updateDatePicker() {
        this.$forceUpdate();
    }
    @Watch('selectedPartner', { deep: true })
    private initPartnerForm() {
        this.originalProblemAccessQuota = this.selectedPartner.problemAccessQuota;
        this.originalProblemSetAccessQuota = this.selectedPartner.problemSetAccessQuota;
        this.partner = _.cloneDeep(this.selectedPartner);
        const { province, city, district, contractStartTime, contractExpireTime } = this.partner;
        this.partner.address = [];
        this.partner.contractTime = [];
        if (province && city && district) {
            this.partner.address = [
                province,
                city,
                district,
            ];
        }
        if (contractStartTime && contractExpireTime) {
            this.partner.contractTime = [
                contractStartTime,
                contractExpireTime,
            ];
        }
    }

    private validateNumber(rule: any, value: any, callback: any) {
        if (!Number.isInteger(value)) {
            callback(new Error('数量 / 配额只能是数字'));
        }
        if (value < 0) {
            callback(new Error('数量 / 配额不能是负数'));
        }
        return callback();
    }
    private clearValidate() {
        this.partnerForm.clearValidate();
    }

    private openAddTemplateCoursesModal() {
        const hasAddedTemplateCoursesId = this.partner.templateCourses.map((course) => {
            return course.courseId;
        });
        this.filteredTemplateCourses = this.templateCourses.filter((course) => {
            return !hasAddedTemplateCoursesId.includes(course.courseId);
        });
        this.addTemplateCoursesModal.$emit('open');
    }

    private addTemplateCourses(courses) {
        this.partner.templateCourses = this.partner.templateCourses.concat(courses);
    }

    private removeTemplateCourse(courseId) {
        this.partner.templateCourses = this.partner.templateCourses.filter((course) => course.courseId !== courseId);
    }

    private submit() {
        this.partnerForm.validate((valid) => {
            if (!valid) {
                return false;
            }
            if (this.partner.address.length <= 0) {
                return this.$message.error('请选择机构地理位置');
            }
            const increasedProblemQuantity = this.partner.problemAccessQuota - this.originalProblemAccessQuota;
            if (increasedProblemQuantity < 0) {
                return this.$message.error('无法手动降低机构自由练题配额，请联系管理员');
            }
            const increasedProblemSetQuantity = this.partner.problemSetAccessQuota - this.originalProblemSetAccessQuota;
            if (increasedProblemSetQuantity < 0) {
                return this.$message.error('无法手动降低机构真题演练配额，请联系管理员');
            }
            let increasedQuantityHint = '';
            let deductedBalance = 0;
            if (increasedProblemQuantity > 0) {
                increasedQuantityHint += `${increasedProblemQuantity} 个自由练题配额，`;
                deductedBalance += this.problemAccessQuotaUnitPrice * increasedProblemQuantity;
            }
            if (increasedProblemSetQuantity > 0) {
                increasedQuantityHint += `${increasedProblemSetQuantity} 个真题演练配额，`;
                deductedBalance += this.problemSetAccessQuotaUnitPrice * increasedProblemSetQuantity;
            }
            if (increasedProblemQuantity > 0 || increasedProblemSetQuantity > 0) {
                this.$confirm(`当前修改将为机构新开通 ${increasedQuantityHint}该操作不可撤销，确认后将扣除 ${this.partner.partnerName} 机构${deductedBalance}元余额。`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    this.requestUpdatePartner();
                }).catch(() => {
                    this.$message.info('已取消');
                });
            } else {
                this.requestUpdatePartner();
            }
        });
    }

    private requestUpdatePartner() {
        this.isRequesting = true;
        axios.post('/api/v1/partner-manage/update-partner', {
            ...this.partner,
            province: this.partner.address[0],
            city: this.partner.address[1],
            district: this.partner.address[2],
            startTime: this.partner.contractTime[0],
            expireTime: this.partner.contractTime[1],
        }).then(() => {
            this.$message.success('保存成功');
            this.isRequesting = false;
            this.showDialog = false;
            this.$emit('refresh-partners');
        }).catch((error) => {
            this.isRequesting = false;
            this.partner.problemAccessQuota = this.originalProblemAccessQuota;
            this.partner.problemSetAccessQuota = this.originalProblemSetAccessQuota;
            this.$message.error(error);
        });
    }
}
