
import { Provide, Component, Ref, Mixins } from 'vue-property-decorator';
import axios from '../../plugins/axios.js';
import CourseClassifyMixins from '@/views/shared/CourseClassifyMixins.vue';
import UpdateVoucherModal from './modals/UpdateVoucherModal.vue';
import VoucherUsersModal from './modals/VoucherUsers.vue';
@Component({
    components: {
        UpdateVoucherModal,
        VoucherUsersModal,
    },
})
export default class VoucherManagement extends Mixins(CourseClassifyMixins) {
    @Provide() private vouchers: any[] = [];
    @Provide() private queriedCondition: { [index: string]: any } = {
        sort: 'descending',
        page: 1,
    };
    @Provide() private pageSize: number = 10;
    @Provide() private loading: boolean = true;
    @Provide() private total: number = 0;
    @Provide() private couponTemplates: any[] = [];
    @Ref() private readonly updateVoucherModal;
    @Ref() private readonly voucherUsersModal;

    private mounted() {
        this.getVouchers();
    }

    private refreshVouchers() {
        this.queriedCondition.page = 1;
        this.getVouchers();
    }

    private changePage(page: number) {
        this.queriedCondition.page = page;
        this.getVouchers();
    }

    private openAddVoucherModal() {
        this.updateVoucherModal.$emit('open');
    }

    private getVouchers() {
        this.loading = true;
        axios.get('/api/v1/vouchers', this.queriedCondition).then(({ vouchers, total, courseClassify, couponTemplates }) => {
            this.loading = false;
            this.vouchers = vouchers;
            this.total = total;
            this.courseClassify = courseClassify;
            this.couponTemplates = couponTemplates;
        }).catch((error) => {
            this.loading = false;
            this.$message.error(error);
        });
    }

    private returnVoucherDurationAsDays(duration: number) {
        return `${Math.floor(duration / 86400)} 天`;
    }

    private toggleVoucherEnabledStatus(voucher) {
        axios.post('/api/v1/voucher/enabled', {
            voucherId: voucher.id,
            isEnabled: voucher.isEnabled,
        }).catch((error) => {
            voucher.isEnabled = voucher.isEnabled ? 0 : 1;
            this.$message.error(error);
        });
    }

    private openVoucherUsersModal(voucher) {
        this.voucherUsersModal.$emit('open', voucher);
    }

    private openEditVoucherModal(voucher) {
        this.updateVoucherModal.$emit('open', voucher);
    }

    private deleteVoucher(voucher) {
        this.$confirm('该权益将会被删除且无法还原，是否继续？', '删除权益', {
            confirmButtonText: '确定',
            confirmButtonClass: 'el-button--danger',
            cancelButtonText: '取消',
        }).then(() => {
            axios.post('/api/v1/voucher/delete', {
                voucherId: voucher.id,
            }).then(() => {
                this.$message({
                    type: 'success',
                    message: '删除成功',
                });
                this.getVouchers();
            }).catch((error) => {
                this.$message.error(error);
            });
        }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消删除',
            });
        });
    }
}
