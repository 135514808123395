
import Vue from 'vue';
import axios from '@/plugins/axios.js';
import { Provide, Component, Prop, Watch, Ref } from 'vue-property-decorator';
@Component({})
export default class UpdateVoucherModal extends Vue {
    @Prop() private courseClassifyOptions;
    @Prop() private couponTemplates;
    @Provide() private title: string = '添加权益';
    @Provide() private voucher: { [index: string]: any } = {
        voucherId: null,
        title: '',
        description: '',
        price: null,
        duration: null,
        type: 'courseIds',
        courseIds: null,
        courseClassifies: null,
        couponTemplate: null,
        benefits: null,
    };
    @Provide() private selectedCouponTemplateIndex: any = null;
    @Provide() private showDialog: boolean = false;
    @Provide() private submitting: boolean = false;
    @Provide() private courseId: string = '';
    @Provide() private courses: any[] = [];
    @Provide() private types: any[] = [
        {
            title: '课程 ID',
            value: 'courseIds',
        },
        {
            title: '课程分类',
            value: 'courseClassifies',
        },
        {
            title: '优惠券',
            value: 'couponTemplate',
        },
        {
            title: '权益',
            value: 'benefits',
        },
    ];
    @Provide() private isLoading: boolean = false;
    @Ref() private readonly voucherForm;
    @Provide() private rules: { [index: string]: object[] } = {
        title: [
            { required: true, message: '请填写标题', trigger: 'blur' },
        ],
        price: [
            { required: true, message: '请填写价格', trigger: 'blur' },
        ],
        duration: [
            { required: true, message: '请填写有效时长', trigger: 'blur' },
        ],
        courseIds: [
            { validator: this.validateCourseIds, trigger: 'blur' },
        ],
        courseClassifies: [
            { validator: this.validateCourseClassifies, trigger: 'blur' },
        ],
        couponTemplate: [
            { validator: this.validateCouponTemplate, trigger: 'blur' },
        ],
        benefits: [
            { validator: this.validateBenefits, trigger: 'blur' },
        ],
    };

    private validateCourseIds(rule: any, value: any, callback: any) {
        if (this.voucher.type === 'courseIds' && value.length === 0) {
            return callback(new Error('请填写可转入的课程 ID'));
        }
        return callback();
    }

    private validateCourseClassifies(rule: any, value: any, callback: any) {
        if (this.voucher.type === 'courseClassifies' && value.length === 0) {
            return callback(new Error('请选择可转入的课程分类'));
        }
        return callback();
    }

    private validateCouponTemplate(rule: any, value: any, callback: any) {
        if (this.voucher.type === 'couponTemplate' && !value) {
            return callback(new Error('请选择可兑换的优惠券模版'));
        }
        return callback();
    }

    private validateBenefits(rule: any, value: any, callback: any) {
        if (this.voucher.type === 'benefits' && value.length === 0) {
            return callback(new Error('请填写可兑换的权益'));
        }
        return callback();
    }

    private handleChange(value) {
        this.voucher.courseClassifies = value;
    }

    private created() {
        this.$on('open', (voucher) => {
            this.showDialog = true;
            if (voucher) {
                this.formatVoucher(voucher);
            }
        });
    }

    private formatVoucher(voucher) {
        this.title = '编辑权益';
        this.voucher.voucherId = voucher.id;
        this.voucher.title = voucher.title;
        this.voucher.description = voucher.description;
        this.voucher.price = voucher.price;
        this.voucher.duration = voucher.duration / 86400;
        let voucherType = 'courseIds';
        if (voucher.courseClassifies) {
            voucherType = 'courseClassifies';
        } else if (voucher.couponTemplate) {
            voucherType = 'couponTemplate';
        } else if (voucher.benefits) {
            voucherType = 'benefits';
        }
        this.voucher.type = voucherType;

        this.$nextTick(() => {
            this.voucher.courseIds = voucher.courseIds;
            this.voucher.courseClassifies = voucher.courseClassifies;
            this.voucher.couponTemplate = voucher.couponTemplate;
            this.voucher.benefits = voucher.benefits;
            if (voucherType === 'couponTemplate') {
                this.getCouponTemplate(voucher.couponTemplate);
            } else if (voucherType === 'courseIds') {
                this.getCoursesTitles(voucher.courseIds);
            }
        });
    }

    private getCouponTemplate(couponTemplate) {
        this.selectedCouponTemplateIndex = this.couponTemplates.findIndex((template) => template.id === couponTemplate.id);
    }

    private getCoursesTitles(courseIds) {
        this.courses = [];
        courseIds.forEach((courseId) => {
            this.getCourseTitle(courseId);
        });
    }

    @Watch('voucher.type', { deep: true })
    private voucherTypeUpdated() {
        this.courses = [];
        this.courseId = '';
        this.voucher.courseIds = null;
        this.voucher.courseClassifies = null;
        this.voucher.couponTemplate = null;
        this.voucher.benefits = null;
    }

    @Watch('courses', { deep: true })
    private updateVoucherCourseIds() {
        if (this.voucher.type === 'courseIds') {
            this.voucher.courseIds = this.courses.map((course) => course.courseId);
        } else {
            this.voucher.courseIds = null;
        }
    }

    @Watch('selectedCouponTemplateIndex', { deep: true })
    private updateVoucherCouponTemplate() {
        if (this.voucher.type === 'couponTemplate') {
            this.voucher.couponTemplate = this.couponTemplates[this.selectedCouponTemplateIndex];
        } else {
            this.voucher.couponTemplate = null;
        }
    }

    private beforeAddingCourseId(courseId) {
        if (!(/^\+?(0|[1-9]\d*)$/).test(courseId)) {
            return this.$message.error('课号必须为纯数字');
        }
        this.getCourseTitle(parseInt(courseId, 0));
    }

    private removeCourse(removedCourseId) {
        this.courses = this.courses.filter((course) => course.courseId !== removedCourseId);
    }

    private getCourseTitle(courseId) {
        this.isLoading = true;
        axios.get('/api/v1/sales/get-course', {
            courseId,
        }).then(({ title }) => {
            this.courseId = '';
            this.courses.push({
                courseId,
                title,
            });
        }).catch((error) => {
            this.$message.error(error);
        }).finally(() => {
            this.isLoading = false;
        });
    }

    private clearValidate() {
        this.voucherForm.clearValidate();
    }

    private confirm() {
        this.voucherForm.validate((valid) => {
            if (!valid) {
                return false;
            }
            this.submitting = true;
            if (this.voucher.voucherId) {
                this.updateVoucher();
            } else {
                this.addVoucher();
            }
        });
    }

    private get formattedParams() {
        return {
            ...this.voucher,
            courseIds: this.voucher.type === 'courseIds' ? this.voucher.courseIds : null,
            courseClassifies: this.voucher.type === 'courseClassifies' ? this.voucher.courseClassifies : null,
            couponTemplate: this.voucher.type === 'couponTemplate' ? this.voucher.couponTemplate : null,
            benefits: this.voucher.type === 'benefits' ? this.voucher.benefits : null,
        };
    }

    private updateVoucher() {
        axios.put('/api/v1/voucher', this.formattedParams).then(() => {
            this.$message({
                type: 'success',
                message: '编辑权益成功',
            });
            this.handleVoucherUpdated();
        }).catch((error) => {
            this.submitting = false;
            this.$message.error(error);
        });
    }

    private addVoucher() {
        axios.post('/api/v1/voucher', this.formattedParams).then(() => {
            this.$message({
                type: 'success',
                message: '添加权益成功',
            });
            this.handleVoucherUpdated();
        }).catch((error) => {
            this.submitting = false;
            this.$message.error(error);
        });
    }

    private handleVoucherUpdated() {
        this.clearForm();
        this.showDialog = false;
        this.submitting = false;
        this.$emit('refresh-vouchers');
    }

    private clearForm() {
        this.voucher = {
            voucherId: null,
            title: '',
            description: '',
            price: null,
            duration: null,
            type: 'courseIds',
            courseIds: null,
            courseClassifies: null,
            couponTemplate: null,
            benefits: null,
        };
        this.courses = [];
        this.courseId = '';
    }
}
