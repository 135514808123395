
import Vue from 'vue';
import { Provide, Component } from 'vue-property-decorator';
import axios from '../../plugins/axios.js';

@Component
export default class PotentialCustomer extends Vue {
    @Provide() private customerActivities: any[] = [];
    @Provide() private activityTypeOptions: any[] = [];
    @Provide() private activityOperatorOptions: any[] = [];
    @Provide() private queriedCondition: { [index: string]: any } = {
        account: '',
        activityTypes: [],
        operators: [],
        page: 1,
    };
    @Provide() private pageSize: number = 10;
    @Provide() private loading: boolean = false;
    @Provide() private total: number = 0;


    private changePage(page: number) {
        this.queriedCondition.page = page;
        this.getCustomerActivities();
    }

    private changeFilter(filter) {
        if (filter.hasOwnProperty('activityTypes')) {
            this.queriedCondition.activityTypes = filter.activityTypes;
        }
        if (filter.hasOwnProperty('operators')) {
            this.queriedCondition.operators = filter.operators;
        }
        this.queriedCondition.page = 1;
        this.getCustomerActivities();
    }

    private getCustomerActivities() {
        this.loading = true;
        axios.get('/api/v1/crm/customer-activities', this.queriedCondition).then(({
            total,
            customerActivities,
            activityTypeOptions,
            activityOperatorOptions,
        }) => {
            this.total = total;
            this.customerActivities = customerActivities;
            this.activityTypeOptions = activityTypeOptions;
            this.activityOperatorOptions = activityOperatorOptions;
        }).catch((error) => {
            this.$message.error(error);
        }).then(() => {
            this.loading = false;
        });
    }
}
