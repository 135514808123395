
import Vue from 'vue';
import { Provide, Component, Prop, Ref } from 'vue-property-decorator';
import StudentCourses from './StudentCourses.vue';
import StudentPausedCourses from './StudentPausedCourses.vue';
import StudentCouponVouchers from './StudentCouponVouchers.vue';
import StudentBenefitsVouchers from './StudentBenefitsVouchers.vue';
@Component({
    components: {
        StudentCourses,
        StudentPausedCourses,
        StudentCouponVouchers,
        StudentBenefitsVouchers,
    },
})
export default class StudentCoursesAndVouchers extends Vue {
    @Prop() public student!: any;
    @Provide() public activeTab: string = 'courses';
    @Ref() private studentCourses;

    private handleStudentCourseUpdated() {
        this.studentCourses.getCourses();
    }
}
