
import Vue from 'vue';
import _ from 'lodash';
import axios from '../../../plugins/axios.js';
import { Component, Provide } from 'vue-property-decorator';

@Component
export default class RefundConfirmationDialog extends Vue {
    @Provide() private isModalVisible: boolean = false;
    @Provide() private isLoading: boolean = false;
    @Provide() private record: any = {};

    private created() {
        this.$on('open', (record) => {
            this.isModalVisible = true;
            this.record = _.cloneDeep(record);
        });
    }

    private submitRefund() {
        this.$confirm('记录退费后无法撤回，是否继续？', '确认记录', {
            confirmButtonText: '确定',
            confirmButtonClass: 'el-button--danger',
            cancelButtonText: '取消',
        }).then(() => {
            this.requestRecordRefund();
        }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消',
            });
        });
    }

    private requestRecordRefund() {
        this.isLoading = true;
        axios.post('/api/v1/transaction/refund-status', this.record).then(() => {
            this.isModalVisible = false;
            this.$emit('refresh-records');
        }).catch((error) => {
            this.$message.error(error);
        }).then(() => {
            this.isLoading = false;
        });
    }
}
